<template>
    <div class="col-12">
        <div class="card">
             <h5>Imóveis Cadastrados</h5> 
            <DataTable :value="database" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="id" :rowHover="true" responsiveLayout="stack" style="width:100%;">
				<template #empty>
					Nenhum registro encontrado.
				</template>
				<template #loading>
					Carregando... Por favor, aguarde.
				</template> 
               
                <Column style="text-align: left; max-width: 300px;">                                  
                    <template #body="data">
                        <div class="container" >                            
                            <div class="box" >  
                                <div class="titulo">{{data.data.nome}}</div>                              
                                <div class="box-row" >                                    
                                    <div class="box-cell" >
                                        <div class="item">
                                            <p><label>CAD/PRO:</label> {{data.data.numeroCadPro}}</p>
                                            <p><label>Matrícula:</label> {{data.data.matricula}}</p>
                                            <p><label>Arrendado:</label>
                                            <span v-if="data.data.arrendado">Sim</span>
                                            <span v-else>Não</span></p>
                                            <p style="float: left; word-break: break-all;"><label>Endereço:</label>{{data.data.endereco}}</p>
                                            <p><label>Cep:</label> {{data.data.cep}}</p>
                                        </div>
                                    </div>
                                    <br/>
                                    <div class="box-cell">
                                        <div class="item">
                                            <p><label>CPF/CNPJ:</label> {{data.data.cnpj}}</p>
                                            <p><label>Bloqueado:</label>
                                                <span v-if="data.data.bloqueado">Sim</span>
                                                <span v-else>Não</span>
                                            </p>
                                            <p><label>INCRA:</label> {{data.data.incra}}</p>
                                            <p><label>Bairro:</label> {{data.data.bairro}}</p>
                                            <p><label>Cidade:</label> {{data.data.cidade}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>                   
                </Column> 
            </DataTable>
        </div>
    </div>
</template>

<script>
    import ImoveisService from '../../service/ImoveisService'

    export default {
        name: 'Imoveis',

        props: {
			matricula: {
				type: Number,
				required: true
			}
		},

         data() {
            return {
                database: [],
                arrendado: null,
            }
        },

        created() {                     
			this.getImoveis();
        },

        methods: { 
            getImoveis(){  
                ImoveisService.getImoveisCadastrados(this.matricula).then((response) => {                                       
                    this.database = response.data;
                });              
            }, 
        },         
    }
</script>

<style scoped lang="scss">

    .container {       
        color: rgb(0, 0, 0);
        margin-bottom: 10px;
        border-radius: 5px;
        padding: 5px;
       
    }
	.box .box-cell { 
        display: table-cell; 
        padding-bottom: 10px;
    }

    .item p{
        width: 400px;
        margin: 0px 10px 0px 0px;
        padding-left: 10px;
    }
    .titulo {
        border-bottom: Solid 1px rgb(223, 220, 220);
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 16px;
        padding: 10px;        
    }
    label {         
        margin-right: 10px;
        font-weight: bold;        
    }
</style>