<template>
    <ImoveisCadastrado :matricula="currentUser.matricula"/>
</template>

<script lang="js">
    
    import ImoveisCadastrado from '../../components/consulta/ImoveisCadastrados.vue'

    export default {

        computed: {
			currentUser() {
				return this.$store.state.auth.user;
			}
		},
        components: { ImoveisCadastrado }
    }

</script>